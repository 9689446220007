import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import request from "../util/request"
import { get } from "lodash"

export const actionMKTCURNumber = createAsyncThunk(
  "daddmktchannelamt/actionMKTCURNumber",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/daDdMktChannelAmt/mkt-cur-number",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionMKTCURArea = createAsyncThunk(
  "daddmktchannelamt/actionMKTCURArea",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/daDdMktChannelAmt/mkt-cur-area",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const initialState = {
  mktCurNumber: [],
  mktCurArea: [],
}

export const slice = createSlice({
  name: "daddmktchannelamt",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(actionMKTCURNumber.fulfilled, (state, action) => {
      state.mktCurNumber = get(action, "payload.data")
    })
    builder.addCase(actionMKTCURNumber.rejected, (state, action) => {
      state.mktCurNumber = []
    })
    builder.addCase(actionMKTCURArea.fulfilled, (state, action) => {
      state.mktCurArea = get(action, "payload.data")
    })
    builder.addCase(actionMKTCURArea.rejected, (state, action) => {
      state.mktCurArea = []
    })
  },
})

export const selectMKTCURNumber = (state) => state.daddmktchannelamt.mktCurNumber
export const selectMKTCURArea = (state) => state.daddmktchannelamt.mktCurArea

export default slice.reducer
