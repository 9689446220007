import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import request from "../util/request"
import { get } from "lodash"

export const actionMAUTre = createAsyncThunk("daddmktuvmau/actionMAUTre", async (params, { rejectWithValue }) => {
  try {
    return await request({
      url: "/daDdMktUVMAU/mau-tre",
      method: "GET",
      params,
    })
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const actionUVAPlf = createAsyncThunk("daddmktuvmau/actionUVAPlf", async (params, { rejectWithValue }) => {
  try {
    return await request({
      url: "/daDdMktUVMAU/uva-plf",
      method: "GET",
      params,
    })
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const actionUVABsn = createAsyncThunk("daddmktuvmau/actionUVABsn", async (params, { rejectWithValue }) => {
  try {
    return await request({
      url: "/daDdMktUVMAU/uva-bsn",
      method: "GET",
      params,
    })
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const actionMAUCURNumber = createAsyncThunk(
  "daddmktuvmau/actionMAUCURNumber",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/daDdMktUVMAU/mau-cur-number",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionMAUCURArea = createAsyncThunk(
  "daddmktuvmau/actionMAUCURArea",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/daDdMktUVMAU/mau-cur-area",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const initialState = {
  MAUTre: [],
  UVAPlf: [],
  UVABsn: [],

  mauCurNumber: [],
  mauCurArea: [],
}

export const slice = createSlice({
  name: "daddmktuvmau",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(actionMAUTre.fulfilled, (state, action) => {
      state.MAUTre = get(action, "payload.data")
    })
    builder.addCase(actionMAUTre.rejected, (state, action) => {
      state.MAUTre = []
    })

    builder.addCase(actionUVAPlf.fulfilled, (state, action) => {
      state.UVAPlf = get(action, "payload.data")
    })
    builder.addCase(actionUVAPlf.rejected, (state, action) => {
      state.UVAPlf = []
    })

    builder.addCase(actionUVABsn.fulfilled, (state, action) => {
      state.UVABsn = get(action, "payload.data")
    })
    builder.addCase(actionUVABsn.rejected, (state, action) => {
      state.UVABsn = []
    })

    builder.addCase(actionMAUCURNumber.fulfilled, (state, action) => {
      state.mauCurNumber = get(action, "payload.data")
    })
    builder.addCase(actionMAUCURNumber.rejected, (state, action) => {
      state.mauCurNumber = []
    })
    builder.addCase(actionMAUCURArea.fulfilled, (state, action) => {
      state.mauCurArea = get(action, "payload.data")
    })
    builder.addCase(actionMAUCURArea.rejected, (state, action) => {
      state.mauCurArea = []
    })
  },
})

export const selectMAUTre = (state) => state.daddmktuvmau.MAUTre
export const selectUVAPlf = (state) => state.daddmktuvmau.UVAPlf
export const selectUVABsn = (state) => state.daddmktuvmau.UVABsn

export const selectMAUCURNumber = (state) => state.daddmktuvmau.mauCurNumber
export const selectMAUCURArea = (state) => state.daddmktuvmau.mauCurArea

export default slice.reducer
