import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import ddaruv from "./ddaruv"
import ddarmember from "./ddarmember"
import crossSell from "./crossSell"
import ddarfunnel from "./ddarfunnel"
import ddargmv from "./ddargmv"
import auth from "./authSlice"
import ddcbbouncerate from "./ddcbbouncerate"
import daddmktchannelamt from "./daddmktchannelamt"
import daddmktuvmau from "./daddmktuvmau"
import daddmktfirstcnt from "./daddmktfirstcnt"
import daddmktmrepeat from "./daddmktmrepeat"
import daddmktcouponeff from "./daddmktcouponeff"
import daddmktrepeat from "./daddmktrepeat"

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["userLogin", "isLogin"],
  version: 1,
}

const reducers = {
  auth: persistReducer(authPersistConfig, auth),
  ddaruv,
  ddarmember,
  crossSell,
  ddarfunnel,
  ddargmv,
  ddcbbouncerate,
  daddmktchannelamt,
  daddmktuvmau,
  daddmktfirstcnt,
  daddmktmrepeat,
  daddmktcouponeff,
  daddmktrepeat,
}

const rootReducer = combineReducers(reducers)

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})

export const persistor = persistStore(store)
