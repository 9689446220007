import { notification } from "antd"
import axios from "axios"
import { defaultTo, get } from "lodash"
import URL from "../constants/url"
import { store } from "../store"
import { ERROR_MESSAGE } from "../constants/app"
import { actionLogout } from "../store/authSlice"

export const instanceAxios = axios.create({
  baseURL: process.env.REACT_APP_API,
})

instanceAxios.defaults.headers.common["Content-Type"] = "application/json"

instanceAxios.interceptors.response.use(
  (response) => {
    if (response.data.status && +response.data.status !== 0) {
      const message = defaultTo(get(response, "data.message"), ERROR_MESSAGE)
      notification.error({ message })
      return Promise.reject(response)
    }
    return response
  },
  (error) => {
    if (!axios.isCancel(error)) {
      const message = defaultTo(get(error, "message"), ERROR_MESSAGE)
      notification.error({ message })
    }

    const { url } = error.config
    const isUrlLogin = url === URL.Login
    if ([401, 403].includes(error.response.status) && !isUrlLogin) {
      store.dispatch(actionLogout())
    }
    return Promise.reject(error)
  },
)

export default function request(options) {
  return instanceAxios(options)
}
