import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import request from "../util/request"
import { get } from "lodash"

export const actionRPCTre = createAsyncThunk("daddmktrepeat/actionRPCTre", async (params, { rejectWithValue }) => {
  try {
    return await request({
      url: "/daDdMktRepeat/rpc-tre",
      method: "GET",
      params,
    })
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const actionRPCQtr = createAsyncThunk("daddmktrepeat/actionRPCQtr", async (params, { rejectWithValue }) => {
  try {
    return await request({
      url: "/daDdMktRepeat/rpc-qtr",
      method: "GET",
      params,
    })
  } catch (error) {
    return rejectWithValue(error)
  }
})

const initialState = {
  rpcTre: [],
  rpcQtr: [],
}

export const slice = createSlice({
  name: "daddmktrepeat",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(actionRPCTre.fulfilled, (state, action) => {
      state.rpcTre = get(action, "payload.data")
    })
    builder.addCase(actionRPCTre.rejected, (state, action) => {
      state.rpcTre = []
    })

    builder.addCase(actionRPCQtr.fulfilled, (state, action) => {
      state.rpcQtr = get(action, "payload.data")
    })
    builder.addCase(actionRPCQtr.rejected, (state, action) => {
      state.rpcQtr = []
    })
  },
})

export const selectRPCTre = (state) => state.daddmktrepeat.rpcTre
export const selectRPCQtr = (state) => state.daddmktrepeat.rpcQtr

export default slice.reducer
