import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { get } from "lodash"
import request from "../util/request"

export const actionBCRCURNumber = createAsyncThunk(
  "ddcbbouncerate/actionBCRCURNumber",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddcbbouncerate/bcr-cur-number",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const actionBCRCURLine = createAsyncThunk(
  "ddcbbouncerate/actionBCRCURLine",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddcbbouncerate/bcr-cur-line",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const actionBCRCHNPUSH = createAsyncThunk(
  "ddcbbouncerate/actionBCRCHNPUSH",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddcbbouncerate/bcr-chn",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const actionBCRCHNDisplay = createAsyncThunk(
  "ddcbbouncerate/actionBCRCHNDisplay",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddcbbouncerate/bcr-chn",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const actionBCRCHNSearch = createAsyncThunk(
  "ddcbbouncerate/actionBCRCHNSearch",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddcbbouncerate/bcr-chn",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const actionBCRCHNNI = createAsyncThunk("ddcbbouncerate/actionBCRCHNNI", async (params, { rejectWithValue }) => {
  try {
    return await request({
      url: "/ddcbbouncerate/bcr-chn",
      method: "GET",
      params,
    })
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const actionBCRPAGAIR = createAsyncThunk(
  "ddcbbouncerate/actionBCRPAGAIR",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddcbbouncerate/bcr-pag",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const actionBCRPAGTNT = createAsyncThunk(
  "ddcbbouncerate/actionBCRPAGTNT",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddcbbouncerate/bcr-pag",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const actionBCRPAGHTL = createAsyncThunk(
  "ddcbbouncerate/actionBCRPAGHTL",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddcbbouncerate/bcr-pag",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const actionBCRPAGETC = createAsyncThunk(
  "ddcbbouncerate/actionBCRPAGETC",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddcbbouncerate/bcr-pag",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const actionBCRComplete = createAsyncThunk(
  "ddcbbouncerate/actionBCRComplete",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddcbbouncerate/bcr-complete",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionBCRCompleteDual = createAsyncThunk(
  "ddcbbouncerate/actionBCRCompleteDual",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddcbbouncerate/bcr-complete-dual",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const initialState = {
  BCRCURNumber: [],
  BCRCURLine: [],
  BCRCHNPUSH: [],
  BCRCHNSearch: [],
  BCRCHNDisplay: [],
  BCRCHNNI: [],
  BCRPAGAIR: [],
  BCRPAGHTL: [],
  BCRPAGTNT: [],
  BCRPAGETC: [],
  BCRComplete: [],
  BCRCompleteDual: [],
}
export const slice = createSlice({
  name: "ddcbbouncerate",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(actionBCRCURNumber.fulfilled, (state, action) => {
        state.BCRCURNumber = get(action, "payload.data")
      })
      .addCase(actionBCRCURNumber.rejected, (state, action) => {
        state.BCRCURNumber = []
      })
      .addCase(actionBCRCURLine.fulfilled, (state, action) => {
        state.BCRCURLine = get(action, "payload.data")
      })
      .addCase(actionBCRCURLine.rejected, (state, action) => {
        state.BCRCURLine = []
      })
      .addCase(actionBCRCHNPUSH.fulfilled, (state, action) => {
        state.BCRCHNPUSH = get(action, "payload.data").map((item) => {
          return {
            ...item,
            time_value: parseFloat(item.time_value),
          }
        })
      })
      .addCase(actionBCRCHNPUSH.rejected, (state, action) => {
        state.BCRCHNPUSH = []
      })
      .addCase(actionBCRCHNSearch.fulfilled, (state, action) => {
        state.BCRCHNSearch = get(action, "payload.data").map((item) => {
          return {
            ...item,
            time_value: parseFloat(item.time_value),
          }
        })
      })
      .addCase(actionBCRCHNSearch.rejected, (state, action) => {
        state.BCRCHNSearch = []
      })
      .addCase(actionBCRCHNDisplay.fulfilled, (state, action) => {
        state.BCRCHNDisplay = get(action, "payload.data").map((item) => {
          return {
            ...item,
            time_value: parseFloat(item.time_value),
          }
        })
      })
      .addCase(actionBCRCHNDisplay.rejected, (state, action) => {
        state.BCRCHNDisplay = []
      })
      .addCase(actionBCRCHNNI.fulfilled, (state, action) => {
        state.BCRCHNNI = get(action, "payload.data").map((item) => {
          return {
            ...item,
            time_value: parseFloat(item.time_value),
          }
        })
      })
      .addCase(actionBCRCHNNI.rejected, (state, action) => {
        state.BCRCHNNI = []
      })
      .addCase(actionBCRPAGAIR.fulfilled, (state, action) => {
        state.BCRPAGAIR = get(action, "payload.data").map((item) => {
          return {
            ...item,
            time_value: parseFloat(item.time_value),
          }
        })
      })
      .addCase(actionBCRPAGAIR.rejected, (state, action) => {
        state.BCRPAGAIR = []
      })
      .addCase(actionBCRPAGHTL.fulfilled, (state, action) => {
        state.BCRPAGHTL = get(action, "payload.data").map((item) => {
          return {
            ...item,
            time_value: parseFloat(item.time_value),
          }
        })
      })
      .addCase(actionBCRPAGHTL.rejected, (state, action) => {
        state.BCRPAGHTL = []
      })
      .addCase(actionBCRPAGTNT.fulfilled, (state, action) => {
        state.BCRPAGTNT = get(action, "payload.data").map((item) => {
          return {
            ...item,
            time_value: parseFloat(item.time_value),
          }
        })
      })
      .addCase(actionBCRPAGTNT.rejected, (state, action) => {
        state.BCRPAGTNT = []
      })
      .addCase(actionBCRPAGETC.fulfilled, (state, action) => {
        state.BCRPAGETC = get(action, "payload.data").map((item) => {
          return {
            ...item,
            time_value: parseFloat(item.time_value),
          }
        })
      })
      .addCase(actionBCRPAGETC.rejected, (state, action) => {
        state.BCRPAGETC = []
      })
      .addCase(actionBCRComplete.fulfilled, (state, action) => {
        state.BCRComplete = get(action, "payload.data").map((item) => {
          return {
            ...item,
            time_value: parseFloat(item.time_value),
          }
        })
      })
      .addCase(actionBCRComplete.rejected, (state, action) => {
        state.BCRComplete = []
      })
      .addCase(actionBCRCompleteDual.fulfilled, (state, action) => {
        state.BCRCompleteDual = get(action, "payload.data").map((item) => {
          return {
            ...item,
            time_value: parseFloat(item.time_value),
          }
        })
      })
      .addCase(actionBCRCompleteDual.rejected, (state, action) => {
        state.BCRCompleteDual = []
      })
  },
})

export const selectBCRCURNumber = (state) => state.ddcbbouncerate.BCRCURNumber
export const selectBCRCURLine = (state) => state.ddcbbouncerate.BCRCURLine
export const selectBCRCHNPUSH = (state) => state.ddcbbouncerate.BCRCHNPUSH
export const selectBCRCHNSearch = (state) => state.ddcbbouncerate.BCRCHNSearch
export const selectBCRCHNDisplay = (state) => state.ddcbbouncerate.BCRCHNDisplay
export const selectBCRCHNNI = (state) => state.ddcbbouncerate.BCRCHNNI
export const selectBCRPAGAIR = (state) => state.ddcbbouncerate.BCRPAGAIR
export const selectBCRPAGHTL = (state) => state.ddcbbouncerate.BCRPAGHTL
export const selectBCRPAGTNT = (state) => state.ddcbbouncerate.BCRPAGTNT
export const selectBCRPAGETC = (state) => state.ddcbbouncerate.BCRPAGETC
export const selectBCRComplete = (state) => state.ddcbbouncerate.BCRComplete
export const selectBCRCompleteDual = (state) => state.ddcbbouncerate.BCRCompleteDual

export default slice.reducer
