import { lazy, Suspense } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import URL from "../../constants/url"
import NotFound from "../pages/Results/NotFound"
import DefaultLayout from "./DefaultLayout"
import MainLayout from "./MainLayout"
import PrivateRoute from "./PrivateRouter"
const DEFAULT_LAYOUT = "default"
const NONE_LAYOUT = "none"

const CustomerBehavior = lazy(() => import("../pages/CustomerBehavior"))
const Home = lazy(() => import("../pages/Home"))
const PageCrossSell = lazy(() => import("../pages/PageCrossSell"))
const Marketing = lazy(() => import("../pages/Marketing"))

const itemRouter = [
  {
    key: URL.Home,
    components: <Home />,
    layout: DEFAULT_LAYOUT,
    private: false,
  },
  {
    key: URL.CrossSell,
    components: <PageCrossSell />,
    layout: DEFAULT_LAYOUT,
    private: false,
  },
  {
    key: URL.Marketing,
    components: <Marketing />,
    layout: DEFAULT_LAYOUT,
    private: false,
  },
  {
    key: URL.CustomerBehavior,
    components: <CustomerBehavior />,
    layout: DEFAULT_LAYOUT,
    private: false,
  },
  {
    key: "/",
    components: <Navigate to={URL.Login} />,
    layout: NONE_LAYOUT,
    private: false,
  },
  {
    key: "*",
    components: <NotFound />,
    layout: NONE_LAYOUT,
    private: false,
  },
]

export default function Routers() {
  return (
    <MainLayout>
      <Routes>
        {itemRouter.map((item) => {
          let element = item.components
          element = <Suspense fallback={null}>{element}</Suspense>
          if (item.layout === DEFAULT_LAYOUT) {
            element = <DefaultLayout>{element}</DefaultLayout>
          }
          if (item.private) {
            element = <PrivateRoute>{element}</PrivateRoute>
          }
          return <Route key={item.key} path={item.key} element={element} />
        })}
      </Routes>
    </MainLayout>
  )
}
