import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { get } from "lodash"
import request from "../util/request"

export const actionGetUserCount = createAsyncThunk(
  "ddarmember/actionGetUserCount",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/ddarmember/user-count",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionSubscriberRoute = createAsyncThunk(
  "ddarmember/actionSubscriberRoute",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/ddarmember/subscriber-by-route",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const actionGetAgeByGender = createAsyncThunk(
  "ddarmember/actionGetAgeByGender",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddarmember/get-age-group-by-gender",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const actionGetGenderByAge = createAsyncThunk(
  "ddarmember/actionGetGenderByAge",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddarmember/get-gender-by-age-group",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const initialState = {
  userCount: [],
  subscriberRoute: [],
  getGenderByAge: [],
  getAgeByGender: [],
}

export const slice = createSlice({
  name: "ddarmember",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(actionGetUserCount.fulfilled, (state, action) => {
        state.userCount = get(action, "payload.data")
      })
      .addCase(actionGetUserCount.rejected, (state, action) => {
        state.userCount = []
      })
      .addCase(actionSubscriberRoute.fulfilled, (state, action) => {
        state.subscriberRoute = get(action, "payload.data")
      })
      .addCase(actionSubscriberRoute.rejected, (state, action) => {
        state.subscriberRoute = []
      })
      .addCase(actionGetGenderByAge.fulfilled, (state, action) => {
        state.getGenderByAge = get(action, "payload.data")
      })
      .addCase(actionGetGenderByAge.rejected, (state, action) => {
        state.getGenderByAge = []
      })
      .addCase(actionGetAgeByGender.fulfilled, (state, action) => {
        state.getAgeByGender = get(action, "payload.data")
      })
      .addCase(actionGetAgeByGender.rejected, (state, action) => {
        state.getAgeByGender = []
      })
  },
})

export const selectUserCount = (state) => state.ddarmember.userCount

export const selectSubscriberRoute = (state) => state.ddarmember.subscriberRoute
export const selectActionGetGenderByAge = (state) => state.ddarmember.getGenderByAge
export const selectActionGetAgeByGender = (state) => state.ddarmember.getAgeByGender

export default slice.reducer
