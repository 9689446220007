import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import {
  CreditCardOutlined,
  FunnelPlotOutlined,
  HomeOutlined,
  LineChartOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons"
import { Layout, Menu, Select, Space } from "antd"
import { actionChangeWebServiceName } from "../../store/crossSell"
import { ReactComponent as LogoConnectIcon } from "../images/logo-connect.svg"
import { ReactComponent as LogoIcon } from "../images/logo.svg"
import { ReactComponent as PriviaIcon } from "../images/privialogo.svg"
import { ReactComponent as TourvisIcon } from "../images/tourvislogo.svg"
import "../../index.css"
import URL from "../../constants/url"
import { SUB_TITLE_HEADER, TITLE_HEADER } from "../../constants/app"

const { Header, Sider } = Layout
const { Option } = Select
const items = [
  {
    key: URL.Home,
    label: "HOME",
    style: {},
    icon: <HomeOutlined style={{ fontSize: 20 }} />,
  },
  {
    key: "salesTrend",
    label: "Sales Trend",
    style: {},
    icon: <MoneyCollectOutlined style={{ fontSize: 20 }} />,
  },
  {
    key: URL.CrossSell,
    label: "Cross-Selling",
    style: {},
    icon: <LineChartOutlined style={{ fontSize: 20 }} />,
  },
  {
    key: URL.Marketing,
    label: "Marketing",
    style: {},
    icon: <CreditCardOutlined style={{ fontSize: 20 }} />,
  },
  {
    key: URL.CustomerBehavior,
    label: "Customer Behavior",
    style: {},
    icon: <FunnelPlotOutlined style={{ fontSize: 20 }} />,
  },
]

export default function DefaultLayout(props) {
  const dispatch = useDispatch()
  const { children } = props
  const navigate = useNavigate()
  const location = useLocation()
  const [selectedKeys, setSelectedKeys] = useState([URL.Home])

  const handleOptionChange = (value) => {
    dispatch(actionChangeWebServiceName(value))
  }

  const handleClickMenu = (event) => {
    setSelectedKeys([event.key])

    if ([URL.CrossSell, URL.CustomerBehavior, URL.Marketing].includes(event.key)) {
      navigate(event.key)
    } else {
      navigate(URL.Home)
    }
  }

  useEffect(() => {
    const savedKey = location.pathname
    setSelectedKeys([savedKey])
    navigate(savedKey)
  }, [])

  return (
    <div className="App">
      <Layout
        className="layout"
        style={{ padding: "20px 5px 20px 20px", minHeight: "100vh", backgroundColor: "rgb(240, 242, 245)" }}
      >
        <Sider theme="light" width={243} style={{ backgroundColor: "rgb(240, 242, 245)" }} id="sidebar">
          <Space.Compact
            block
            direction="vertical"
            style={{
              width: "100%",
              padding: "48px 24px",
              borderRadius: 20,
              backgroundColor: "#fff",
            }}
          >
            <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
              <LogoConnectIcon />
            </Space>

            <Menu
              className="icon-lock"
              theme="light"
              mode="inline"
              style={{
                border: "none",
                marginTop: 38
              }}
              onClick={handleClickMenu}
            >
              {items.map((item) => (
                <Menu.Item
                  key={item.key}
                  className="sider-menu-item"
                  style={{
                    color: selectedKeys[0] === item.key ? "#fff" : "#000000",
                    backgroundColor: selectedKeys[0] === item.key ? "#444" : "transparent",
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    ...item.style,
                  }}
                >
                  <span style={{ marginRight: "10px", opacity: 0.6 }}>{item.icon}</span>
                  <span style={{ opacity: 0.8 }}>{item.label}</span>
                </Menu.Item>
              ))}
            </Menu>
          </Space.Compact>
        </Sider>

        <Layout>
          <Header
            className="header-check"
            style={{
              backgroundColor: "rgb(240, 242, 245)",
              display: "flex",
              alignItems: "center",
              paddingLeft: "40px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: 4, backgroundColor: "rgb(240, 242, 245)" }}>
              <div style={{ display: "flex", gap: 10 }}>
                <span className="title-header">{TITLE_HEADER}</span>
                <div>
                  <Select defaultValue="tourvis" style={{ width: 180 }} onChange={handleOptionChange} bordered={false}>
                    <Option value="tidesquare" label="Tidesquare">
                      <LogoIcon />
                    </Option>
                    <Option value="tourvis" label="Tourvis">
                      <TourvisIcon />
                    </Option>
                    <Option value="privia" label="Privia">
                      <PriviaIcon />
                    </Option>
                  </Select>
                </div>
              </div>

              <span className="sub-title-header">{SUB_TITLE_HEADER}</span>
            </div>
          </Header>
          {children}
        </Layout>
      </Layout>
    </div>
  )
}
