import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import URL from "../../constants/url"
import { selectIsLogin, selectToken } from "../../store/authSlice"
import { instanceAxios } from "../../util/request"

export default function PrivateRoute({ children }) {
  const isLogin = useSelector(selectIsLogin)
  const token = useSelector(selectToken)

  useEffect(() => {
    if (token) {
      instanceAxios.defaults.headers.common.Authorization = `Bearer ${token}`
    }
  }, [token])

  if (isLogin) {
    return children
  }

  return <Navigate to={URL.Login} replace />
}
