import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { get } from "lodash"
import request from "../util/request"

export const actionFunnelCVR = createAsyncThunk("ddarfunnel/actionFunnelCVR", async (params, { rejectWithValue }) => {
  try {
    return await request({
      url: "/ddarfunnel/cvr",
      method: "GET",
      params,
    })
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const actionFunnel = createAsyncThunk("ddarfunnel/actionFunnel", async (params, { rejectWithValue }) => {
  try {
    return await request({
      url: "/ddarfunnel/funnel",
      method: "GET",
      params,
    })
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const actionDualColumnLine = createAsyncThunk(
  "ddarfunnel/actionDualColumnLine",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddarfunnel/dual-column-line",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const actionCVRCHNPush = createAsyncThunk("ddarfunnel/actionCVRCHNPush", async (params, { rejectWithValue }) => {
  try {
    return await request({
      url: "/ddarfunnel/CVR-CHN",
      method: "GET",
      params,
    })
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const actionCVRCHNSearch = createAsyncThunk(
  "ddarfunnel/actionCVRCHNSearch",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddarfunnel/CVR-CHN",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const actionCVRCHNDisplay = createAsyncThunk(
  "ddarfunnel/actionCVRCHNDisplay",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddarfunnel/CVR-CHN",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const actionCVRCHNNI = createAsyncThunk("ddarfunnel/actionCVRCHNNI", async (params, { rejectWithValue }) => {
  try {
    return await request({
      url: "/ddarfunnel/CVR-CHN",
      method: "GET",
      params,
    })
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const actionCVRCURNumber = createAsyncThunk(
  "ddarfunnel/actionCVRCURNumber",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddarfunnel/CVR-CUR-Number",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const actionCVRCURLine = createAsyncThunk("ddarfunnel/actionCVRCURLine", async (params, { rejectWithValue }) => {
  try {
    return await request({
      url: "/ddarfunnel/CVR-CUR-Line",
      method: "GET",
      params,
    })
  } catch (error) {
    return rejectWithValue(error)
  }
})

const initialState = {
  funnelCVR: [],
  funnel: [],
  dualColumnLine: [],
  CVRCHNPush: [],
  CVRCHNSearch: [],
  CVRCHNDisplay: [],
  CVRCHNNI: [],
  CVRCURNumber: [],
  CVRCURLine: [],
}
export const slice = createSlice({
  name: "ddarfunnel",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(actionFunnelCVR.fulfilled, (state, action) => {
        state.funnelCVR = get(action, "payload.data")
      })
      .addCase(actionFunnelCVR.rejected, (state, action) => {
        state.funnelCVR = []
      })
      .addCase(actionFunnel.fulfilled, (state, action) => {
        state.funnel = get(action, "payload.data")
      })
      .addCase(actionFunnel.rejected, (state, action) => {
        state.funnel = []
      })
      .addCase(actionDualColumnLine.fulfilled, (state, action) => {
        state.dualColumnLine = get(action, "payload.data")
      })
      .addCase(actionDualColumnLine.rejected, (state, action) => {
        state.dualColumnLine = []
      })
      .addCase(actionCVRCHNPush.fulfilled, (state, action) => {
        state.CVRCHNPush = get(action, "payload.data")
      })
      .addCase(actionCVRCHNPush.rejected, (state, action) => {
        state.CVRCHNPush = []
      })
      .addCase(actionCVRCHNSearch.fulfilled, (state, action) => {
        state.CVRCHNSearch = get(action, "payload.data")
      })
      .addCase(actionCVRCHNSearch.rejected, (state, action) => {
        state.CVRCHNSearch = []
      })
      .addCase(actionCVRCHNDisplay.fulfilled, (state, action) => {
        state.CVRCHNDisplay = get(action, "payload.data")
      })
      .addCase(actionCVRCHNDisplay.rejected, (state, action) => {
        state.CVRCHNDisplay = []
      })
      .addCase(actionCVRCHNNI.fulfilled, (state, action) => {
        state.CVRCHNNI = get(action, "payload.data")
      })
      .addCase(actionCVRCHNNI.rejected, (state, action) => {
        state.CVRCHNNI = []
      })
      .addCase(actionCVRCURNumber.fulfilled, (state, action) => {
        state.CVRCURNumber = get(action, "payload.data")
      })
      .addCase(actionCVRCURNumber.rejected, (state, action) => {
        state.CVRCURNumber = []
      })
      .addCase(actionCVRCURLine.fulfilled, (state, action) => {
        state.CVRCURLine = get(action, "payload.data")
      })
      .addCase(actionCVRCURLine.rejected, (state, action) => {
        state.CVRCURLine = []
      })
  },
})

export const selectFunnelCVR = (state) => state.ddarfunnel.funnelCVR
export const selectFunnel = (state) => state.ddarfunnel.funnel
export const selectDualColumnLine = (state) => state.ddarfunnel.dualColumnLine
export const selectCVRCHNDisplay = (state) => state.ddarfunnel.CVRCHNDisplay
export const selectCVRCHNPush = (state) => state.ddarfunnel.CVRCHNPush
export const selectCVRCHNSearch = (state) => state.ddarfunnel.CVRCHNSearch
export const selectCVRCHNNI = (state) => state.ddarfunnel.CVRCHNNI
export const selectCVRCURNumber = (state) => state.ddarfunnel.CVRCURNumber
export const selectCVRCURLine = (state) => state.ddarfunnel.CVRCURLine

export default slice.reducer
