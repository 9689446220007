import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { get } from "lodash"
import request from "../util/request"
import { WEB_SERVICE_NAME } from "../constants/app"

export const actionGetCrossSellMasterPrivia = createAsyncThunk(
  "crossSell/actionGetCrossSellMasterPrivia",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/cross-sell-master-privia",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionGetCrossSellMasterTourvis = createAsyncThunk(
  "crossSell/actionGetCrossSellMasterTourvis",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/cross-sell-master-tourvis",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionGetResidualExclusivSales = createAsyncThunk(
  "crossSell/actionGetResidualExclusivSales",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/cross-sell-master-tourvis/residual-exclusiv-sales",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionGetPriviaResidualExclusivSales = createAsyncThunk(
  "crossSell/actionGetPriviaResidualExclusivSales",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/cross-sell-master-privia/residual-exclusiv-sales",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionGetNewSales = createAsyncThunk(
  "crossSell/actionGetNewSales",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/cross-sell-master-tourvis/new-sales",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionGetPriviaNewSales = createAsyncThunk(
  "crossSell/actionGetPriviaNewSales",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/cross-sell-master-privia/new-sales",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionCrossSell = createAsyncThunk("crossSell/actionCrossSell", async (params, { rejectWithValue }) => {
  try {
    const response = await request({
      url: "/cross-sell-master-tourvis/cross-sell",
      method: "GET",
      params,
    })
    return response
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const actionPriviaCrossSell = createAsyncThunk(
  "crossSell/actionPriviaCrossSell",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/cross-sell-master-privia/cross-sell",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionCrossSellingShare = createAsyncThunk(
  "crossSell/actionCrossSellingShare",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/cross-sell-master-tourvis/cross-selling-share",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionPriviaCrossSellingShare = createAsyncThunk(
  "crossSell/actionPriviaCrossSellingShare",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/cross-sell-master-privia/cross-selling-share",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionCrossSellLeadTime = createAsyncThunk(
  "crossSell/actionCrossSellLeadTime",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/cross-sell-lead-time-tourvis",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionPriviaCrossSellLeadTime = createAsyncThunk(
  "crossSell/actionPriviaCrossSellLeadTime",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/cross-sell-lead-time-privia",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionGetCrossSellWithCrossType = createAsyncThunk(
  "crossSell/actionGetCrossSellWithCrossType",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/cross-sell-master-tourvis/get-cross-sell-with-cross-type",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionGetPriviaCrossSellWithCrossType = createAsyncThunk(
  "crossSell/actionGetPriviaCrossSellWithCrossType",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/cross-sell-master-privia/get-cross-sell-with-cross-type",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionGetResidualExclusivWithCrossType = createAsyncThunk(
  "crossSell/actionGetResidualExclusivWithCrossType",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/cross-sell-master-tourvis/residual-exclusiv-sales-with-cross-type",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionGetPriviaResidualExclusivWithCrossType = createAsyncThunk(
  "crossSell/actionGetPriviaResidualExclusivWithCrossType",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/cross-sell-master-privia/residual-exclusiv-sales-with-cross-type",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionGetCountry = createAsyncThunk("crossSell/actionGetCountry", async (params, { rejectWithValue }) => {
  try {
    const response = await request({
      url: "/cross-sell-master-tourvis/get-country",
      method: "GET",
      params,
    })
    return response
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const actionGetPriviaCountry = createAsyncThunk(
  "crossSell/actionGetPriviaCountry",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/cross-sell-master-privia/get-country",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const initialState = {
  crossSellMasterPrivia: [],
  crossSellMasterTourvis: [],
  residualExclusivSales: [],
  priviaResidualExclusivSales: [],
  newSales: [],
  priviaNewSales: [],
  crossSell: [],
  priviaCrossSell: [],
  crossSellingShare: [],
  priviaCrossSellingShare: [],
  airlineHotelLeadTime: [],
  airlineInjectionLeadTime: [],
  hotelAirlineLeadTime: [],
  hotelInjectionLeadTime: [],
  injectionAirlineLeadTime: [],
  injectionHotelLeadTime: [],
  priviaAirlineHotelLeadTime: [],
  priviaAirlineInjectionLeadTime: [],
  priviaHotelAirlineLeadTime: [],
  priviaHotelInjectionLeadTime: [],
  priviaInjectionAirlineLeadTime: [],
  priviaInjectionHotelLeadTime: [],
  countries: [],
  priviaCountries: [],
  airlineHotelCrossSell: [],
  airlineInjectionCrossSell: [],
  hotelAirlineCrossSell: [],
  hotelInjectionCrossSell: [],
  injectionAirlineCrossSell: [],
  injectionHotelCrossSell: [],
  priviaAirlineHotelCrossSell: [],
  priviaAirlineInjectionCrossSell: [],
  priviaHotelAirlineCrossSell: [],
  priviaHotelInjectionCrossSell: [],
  priviaInjectionAirlineCrossSell: [],
  priviaInjectionHotelCrossSell: [],
  airlineHotelResidualExclusiv: [],
  airlineInjectionResidualExclusiv: [],
  hotelAirlineResidualExclusiv: [],
  hotelInjectionResidualExclusiv: [],
  injectionAirlineResidualExclusiv: [],
  injectionHotelResidualExclusiv: [],
  priviaAirlineHotelResidualExclusiv: [],
  priviaAirlineInjectionResidualExclusiv: [],
  priviaHotelAirlineResidualExclusiv: [],
  priviaHotelInjectionResidualExclusiv: [],
  priviaInjectionAirlineResidualExclusiv: [],
  priviaInjectionHotelResidualExclusiv: [],
  webServiceName: "tourvis",
}

export const slice = createSlice({
  name: "crossSell",
  initialState,
  reducers: {
    actionChangeWebServiceName(state, payload) {
      if (payload.payload === WEB_SERVICE_NAME.TIDESQUARE) {
        state.webServiceName = WEB_SERVICE_NAME.TIDESQUARE
      }
      if (payload.payload === WEB_SERVICE_NAME.TOURVIS) {
        state.webServiceName = WEB_SERVICE_NAME.TOURVIS
      }
      if (payload.payload === WEB_SERVICE_NAME.PRIVIA) {
        state.webServiceName = WEB_SERVICE_NAME.PRIVIA
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(actionGetCrossSellMasterPrivia.fulfilled, (state, action) => {
        const response = get(action, "payload.data").map((item) => {
          return {
            ...item,
            date: item.date.substring(0, 10).replaceAll("-", ""),
          }
        })
        state.crossSellMasterPrivia = response
      })
      .addCase(actionGetCrossSellMasterPrivia.rejected, (state, action) => {
        state.crossSellMasterPrivia = []
      })
      .addCase(actionGetCrossSellMasterTourvis.fulfilled, (state, action) => {
        const response = get(action, "payload.data").map((item) => {
          return {
            ...item,
            date: item.date.substring(0, 10).replaceAll("-", ""),
          }
        })
        state.crossSellMasterTourvis = response
      })
      .addCase(actionGetCrossSellMasterTourvis.rejected, (state, action) => {
        state.crossSellMasterTourvis = []
      })
      .addCase(actionGetResidualExclusivSales.fulfilled, (state, action) => {
        const response = get(action, "payload.data").map((item) => {
          item.date = parseInt(item.date.substring(0, 10).replaceAll("-", ""), 10)
          return item
        })
        state.residualExclusivSales = response
      })
      .addCase(actionGetResidualExclusivSales.rejected, (state, action) => {
        state.residualExclusivSales = []
      })
      .addCase(actionGetPriviaResidualExclusivSales.fulfilled, (state, action) => {
        const response = get(action, "payload.data").map((item) => {
          item.date = parseInt(item.date.substring(0, 10).replaceAll("-", ""), 10)
          return item
        })
        state.priviaResidualExclusivSales = response
      })
      .addCase(actionGetPriviaResidualExclusivSales.rejected, (state, action) => {
        state.priviaResidualExclusivSales = []
      })
      .addCase(actionGetNewSales.fulfilled, (state, action) => {
        state.newSales = get(action, "payload.data").map((item) => {
          item.date = parseInt(item.date.substring(0, 10).replaceAll("-", ""), 10)
          return item
        })
      })
      .addCase(actionGetNewSales.rejected, (state, action) => {
        state.newSales = []
      })
      .addCase(actionGetPriviaNewSales.fulfilled, (state, action) => {
        state.priviaNewSales = get(action, "payload.data").map((item) => {
          item.date = parseInt(item.date.substring(0, 10).replaceAll("-", ""), 10)
          return item
        })
      })
      .addCase(actionGetPriviaNewSales.rejected, (state, action) => {
        state.priviaNewSales = []
      })
      .addCase(actionCrossSell.fulfilled, (state, action) => {
        const response = get(action, "payload.data").map((item) => {
          item.date = parseInt(item.date.substring(0, 10).replaceAll("-", ""), 10)
          return item
        })
        state.crossSell = response
      })
      .addCase(actionCrossSell.rejected, (state, action) => {
        state.crossSell = []
      })
      .addCase(actionPriviaCrossSell.fulfilled, (state, action) => {
        const response = get(action, "payload.data").map((item) => {
          item.date = parseInt(item.date.substring(0, 10).replaceAll("-", ""), 10)
          return item
        })
        state.priviaCrossSell = response
      })
      .addCase(actionPriviaCrossSell.rejected, (state, action) => {
        state.priviaCrossSell = []
      })
      .addCase(actionCrossSellingShare.fulfilled, (state, action) => {
        const response = get(action, "payload.data")
          .map((item) => {
            item.date = parseInt(item.date.substring(0, 10).replaceAll("-", ""), 10)
            return item
          })
          .sort((a, b) => a.date - b.date)
        state.crossSellingShare = response
      })
      .addCase(actionCrossSellingShare.rejected, (state, action) => {
        state.crossSellingShare = []
      })
      .addCase(actionPriviaCrossSellingShare.fulfilled, (state, action) => {
        state.priviaCrossSellingShare = get(action, "payload.data")
          .map((item) => {
            item.date = parseInt(item.date.substring(0, 10).replaceAll("-", ""), 10)
            return item
          })
          .sort((a, b) => a.date - b.date)
      })
      .addCase(actionPriviaCrossSellingShare.rejected, (state, action) => {
        state.priviaCrossSellingShare = []
      })
      .addCase(actionCrossSellLeadTime.fulfilled, (state, action) => {
        const response = get(action, "payload.data")
        switch (`${response.query.biz1_kr},${response.query.biz2_kr}`) {
          case "항공,호텔":
            state.airlineHotelLeadTime = response.result
            break
          case "항공,투티":
            state.airlineInjectionLeadTime = response.result
            break
          case "호텔,항공":
            state.hotelAirlineLeadTime = response.result
            break
          case "호텔,투티":
            state.hotelInjectionLeadTime = response.result
            break
          case "투티,항공":
            state.injectionAirlineLeadTime = response.result
            break
          case "투티,호텔":
            state.injectionHotelLeadTime = response.result
            break
          default:
          // do nothing
        }
      })
      .addCase(actionCrossSellLeadTime.rejected, (state, action) => {
        state.crossSellLeadTime = []
      })
      .addCase(actionPriviaCrossSellLeadTime.fulfilled, (state, action) => {
        const response = get(action, "payload.data")
        switch (`${response.query.biz1_kr},${response.query.biz2_kr}`) {
          case "항공,호텔":
            state.priviaAirlineHotelLeadTime = response.result
            break
          case "항공,투티":
            state.priviaAirlineInjectionLeadTime = response.result
            break
          case "호텔,항공":
            state.priviaHotelAirlineLeadTime = response.result
            break
          case "호텔,투티":
            state.priviaHotelInjectionLeadTime = response.result
            break
          case "투티,항공":
            state.priviaInjectionAirlineLeadTime = response.result
            break
          case "투티,호텔":
            state.priviaInjectionHotelLeadTime = response.result
            break
          default:
          // do nothing
        }
      })
      .addCase(actionPriviaCrossSellLeadTime.rejected, (state, action) => {
        state.priviaCrossSellLeadTime = []
      })
      .addCase(actionGetCountry.fulfilled, (state, action) => {
        state.countries = get(action, "payload.data")
      })
      .addCase(actionGetCountry.rejected, (state, action) => {
        state.countries = []
      })
      .addCase(actionGetPriviaCountry.fulfilled, (state, action) => {
        state.priviaCountries = get(action, "payload.data")
      })
      .addCase(actionGetPriviaCountry.rejected, (state, action) => {
        state.priviaCountries = []
      })
      .addCase(actionGetCrossSellWithCrossType.fulfilled, (state, action) => {
        const response = get(action, "payload.data")
        const result = response.result.map((item) => {
          item.date = parseInt(item.date.substring(0, 10).replaceAll("-", ""), 10)
          return item
        })
        switch (`${response.query.biz1_kr},${response.query.biz2_kr}`) {
          case "항공,호텔":
            state.airlineHotelCrossSell = result
            break
          case "항공,투티":
            state.airlineInjectionCrossSell = result
            break
          case "호텔,항공":
            state.hotelAirlineCrossSell = result
            break
          case "호텔,투티":
            state.hotelInjectionCrossSell = result
            break
          case "투티,항공":
            state.injectionAirlineCrossSell = result
            break
          case "투티,호텔":
            state.injectionHotelCrossSell = result
            break
          default:
          // do nothing
        }
      })
      .addCase(actionGetCrossSellWithCrossType.rejected, (state, action) => {})
      .addCase(actionGetPriviaCrossSellWithCrossType.fulfilled, (state, action) => {
        const response = get(action, "payload.data")
        const result = response.result.map((item) => {
          item.date = parseInt(item.date.substring(0, 10).replaceAll("-", ""), 10)
          return item
        })
        switch (`${response.query.biz1_kr},${response.query.biz2_kr}`) {
          case "항공,호텔":
            state.priviaAirlineHotelCrossSell = result
            break
          case "항공,투티":
            state.priviaAirlineInjectionCrossSell = result
            break
          case "호텔,항공":
            state.priviaHotelAirlineCrossSell = result
            break
          case "호텔,투티":
            state.priviaHotelInjectionCrossSell = result
            break
          case "투티,항공":
            state.priviaInjectionAirlineCrossSell = result
            break
          case "투티,호텔":
            state.priviaInjectionHotelCrossSell = result
            break
          default:
          // do nothing
        }
      })
      .addCase(actionGetPriviaCrossSellWithCrossType.rejected, (state, action) => {})
      .addCase(actionGetResidualExclusivWithCrossType.fulfilled, (state, action) => {
        const response = get(action, "payload.data")
        const result = response.result.map((item) => {
          item.date = parseInt(item.date.substring(0, 10).replaceAll("-", ""), 10)
          return item
        })
        switch (`${response.query.biz1_kr},${response.query.biz2_kr}`) {
          case "항공,호텔":
            state.airlineHotelResidualExclusiv = result
            break
          case "항공,투티":
            state.airlineInjectionResidualExclusiv = result
            break
          case "호텔,항공":
            state.hotelAirlineResidualExclusiv = result
            break
          case "호텔,투티":
            state.hotelInjectionResidualExclusiv = result
            break
          case "투티,항공":
            state.injectionAirlineResidualExclusiv = result
            break
          case "투티,호텔":
            state.injectionHotelResidualExclusiv = result
            break
          default:
          // do nothing
        }
      })
      .addCase(actionGetResidualExclusivWithCrossType.rejected, (state, action) => {})
      .addCase(actionGetPriviaResidualExclusivWithCrossType.fulfilled, (state, action) => {
        const response = get(action, "payload.data")
        const result = response.result.map((item) => {
          item.date = parseInt(item.date.substring(0, 10).replaceAll("-", ""), 10)
          return item
        })
        switch (`${response.query.biz1_kr},${response.query.biz2_kr}`) {
          case "항공,호텔":
            state.priviaAirlineHotelResidualExclusiv = result
            break
          case "항공,투티":
            state.priviaAirlineInjectionResidualExclusiv = result
            break
          case "호텔,항공":
            state.priviaHotelAirlineResidualExclusiv = result
            break
          case "호텔,투티":
            state.priviaHotelInjectionResidualExclusiv = result
            break
          case "투티,항공":
            state.priviaInjectionAirlineResidualExclusiv = result
            break
          case "투티,호텔":
            state.priviaInjectionHotelResidualExclusiv = result
            break
          default:
          // do nothing
        }
      })
      .addCase(actionGetPriviaResidualExclusivWithCrossType.rejected, (state, action) => {})
  },
})

export const { actionChangeWebServiceName } = slice.actions

export const selectWebServiceName = (state) => state.crossSell.webServiceName

export const selectCrossSellMasterPrivia = (state) => state.crossSell.crossSellMasterPrivia

export const selectCrossSellMasterTourvis = (state) => state.crossSell.crossSellMasterTourvis

export const selectResidualExclusivSales = (state) => state.crossSell.residualExclusivSales

export const selectPriviaResidualExclusivSales = (state) => state.crossSell.priviaResidualExclusivSales

export const selectNewSales = (state) => state.crossSell.newSales

export const selectPriviaNewSales = (state) => state.crossSell.priviaNewSales

export const selectCrossSell = (state) => state.crossSell.crossSell

export const selectPriviaCrossSell = (state) => state.crossSell.priviaCrossSell

export const selectCrossSellingShare = (state) => state.crossSell.crossSellingShare

export const selectPriviaCrossSellingShare = (state) => state.crossSell.priviaCrossSellingShare

export const selectAirlineHotelLeadTime = (state) => state.crossSell.airlineHotelLeadTime

export const selectAirlineInjectionLeadTime = (state) => state.crossSell.airlineInjectionLeadTime

export const selectHotelAirlineLeadTime = (state) => state.crossSell.hotelAirlineLeadTime

export const selectHotelInjectionLeadTime = (state) => state.crossSell.hotelInjectionLeadTime

export const selectInjectionAirlineLeadTime = (state) => state.crossSell.injectionAirlineLeadTime

export const selectInjectionHotelLeadTime = (state) => state.crossSell.injectionHotelLeadTime

export const selectPriviaAirlineHotelLeadTime = (state) => state.crossSell.priviaAirlineHotelLeadTime

export const selectPriviaAirlineInjectionLeadTime = (state) => state.crossSell.priviaAirlineInjectionLeadTime

export const selectPriviaHotelAirlineLeadTime = (state) => state.crossSell.priviaHotelAirlineLeadTime

export const selectPriviaHotelInjectionLeadTime = (state) => state.crossSell.priviaHotelInjectionLeadTime

export const selectPriviaInjectionAirlineLeadTime = (state) => state.crossSell.priviaInjectionAirlineLeadTime

export const selectPriviaInjectionHotelLeadTime = (state) => state.crossSell.priviaInjectionHotelLeadTime

export const selectAirlineHotelCrossSell = (state) => state.crossSell.airlineHotelCrossSell

export const selectAirlineInjectionCrossSell = (state) => state.crossSell.airlineInjectionCrossSell

export const selectHotelAirlineCrossSell = (state) => state.crossSell.hotelAirlineCrossSell

export const selectHotelInjectionCrossSell = (state) => state.crossSell.hotelInjectionCrossSell

export const selectInjectionAirlineCrossSell = (state) => state.crossSell.injectionAirlineCrossSell

export const selectInjectionHotelCrossSell = (state) => state.crossSell.injectionHotelCrossSell

export const selectPriviaAirlineHotelCrossSell = (state) => state.crossSell.priviaAirlineHotelCrossSell

export const selectPriviaAirlineInjectionCrossSell = (state) => state.crossSell.priviaAirlineInjectionCrossSell

export const selectPriviaHotelAirlineCrossSell = (state) => state.crossSell.priviaHotelAirlineCrossSell

export const selectPriviaHotelInjectionCrossSell = (state) => state.crossSell.priviaHotelInjectionCrossSell

export const selectPriviaInjectionAirlineCrossSell = (state) => state.crossSell.priviaInjectionAirlineCrossSell

export const selectPriviaInjectionHotelCrossSell = (state) => state.crossSell.priviaInjectionHotelCrossSell

export const selectCountry = (state) => state.crossSell.countries

export const selectPriviaCountry = (state) => state.crossSell.priviaCountries

export const selectAirlineHotelResidualExclusiv = (state) => state.crossSell.airlineHotelResidualExclusiv

export const selectAirlineInjectionResidualExclusiv = (state) => state.crossSell.airlineInjectionResidualExclusiv

export const selectHotelAirlineResidualExclusiv = (state) => state.crossSell.hotelAirlineResidualExclusiv

export const selectHotelInjectionResidualExclusiv = (state) => state.crossSell.hotelInjectionResidualExclusiv

export const selectInjectionAirlineResidualExclusiv = (state) => state.crossSell.injectionAirlineResidualExclusiv

export const selectInjectionHotelResidualExclusiv = (state) => state.crossSell.injectionHotelResidualExclusiv

export const selectPriviaAirlineHotelResidualExclusiv = (state) => state.crossSell.priviaAirlineHotelResidualExclusiv

export const selectPriviaAirlineInjectionResidualExclusiv = (state) =>
  state.crossSell.priviaAirlineInjectionResidualExclusiv

export const selectPriviaHotelAirlineResidualExclusiv = (state) => state.crossSell.priviaHotelAirlineResidualExclusiv

export const selectPriviaHotelInjectionResidualExclusiv = (state) =>
  state.crossSell.priviaHotelInjectionResidualExclusiv

export const selectPriviaInjectionAirlineResidualExclusiv = (state) =>
  state.crossSell.priviaInjectionAirlineResidualExclusiv

export const selectPriviaInjectionHotelResidualExclusiv = (state) =>
  state.crossSell.priviaInjectionHotelResidualExclusiv

export default slice.reducer
