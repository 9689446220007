import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { get } from "lodash"
import request from "../util/request"

export const actionAcquisitionSection = createAsyncThunk(
  "ddaruv/actionAcquisitionSection",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/ddaruv/acquisition-section",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionAARRR = createAsyncThunk("ddaruv/actionAARRR", async (params, { rejectWithValue }) => {
  try {
    const response = await request({
      url: "/ddaruv/aarrr",
      method: "GET",
      params,
    })
    return response
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const actionAcquisitionDisclose = createAsyncThunk(
  "ddaruv/actionAcquisitionDisclose",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/ddaruv/acquisition-disclose",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const actionGetVisitorWithinTime = createAsyncThunk(
  "ddaruv/actionGetVisitorWithinTime",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddaruv/get-visitor-within-time",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionGetVisitorByYesterday = createAsyncThunk(
  "ddaruv/actionGetVisitorByYesterday",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/ddaruv/get-visitor-by-yesterday",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const initialState = {
  acquisitionSections: [],
  acquisitionDisclose: [],
  getVisitorByYesterday: [],
  getVisitorWithinTime: [],
  AARRR: [],
}

export const slice = createSlice({
  name: "ddaruv",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(actionAcquisitionSection.fulfilled, (state, action) => {
        state.acquisitionSections = get(action, "payload.data")
      })
      .addCase(actionAcquisitionSection.rejected, (state, action) => {
        state.acquisitionSections = []
      })
      .addCase(actionAcquisitionDisclose.fulfilled, (state, action) => {
        state.acquisitionDisclose = get(action, "payload.data")
      })
      .addCase(actionAcquisitionDisclose.rejected, (state, action) => {
        state.acquisitionDisclose = []
      })
      .addCase(actionAARRR.fulfilled, (state, action) => {
        state.AARRR = get(action, "payload.data")
      })
      .addCase(actionAARRR.rejected, (state, action) => {
        state.AARRR = []
      })
      .addCase(actionGetVisitorByYesterday.fulfilled, (state, action) => {
        state.getVisitorByYesterday = get(action, "payload.data")
      })
      .addCase(actionGetVisitorByYesterday.rejected, (state, action) => {
        state.getVisitorByYesterday = []
      })
      .addCase(actionGetVisitorWithinTime.fulfilled, (state, action) => {
        state.getVisitorWithinTime = get(action, "payload.data")
      })
      .addCase(actionGetVisitorWithinTime.rejected, (state, action) => {
        state.getVisitorWithinTime = []
      })
  },
})

export const selectAcquisitionSection = (state) => state.ddaruv.acquisitionSections

export const selectAcquisitionDisclose = (state) => state.ddaruv.acquisitionDisclose

export const selectAARRR = (state) => state.ddaruv.AARRR
export const selectActionGetVisitorByYesterday = (state) => state.ddaruv.getVisitorByYesterday
export const selectActionGetVisitorWithinTime = (state) => state.ddaruv.getVisitorWithinTime

export default slice.reducer
