import { Layout } from "antd"

const { Content } = Layout

const MainLayout = ({ children }) => (
  <Layout>
    <Content className="site-layout">
      <div className="site-layout-background">{children}</div>
    </Content>
  </Layout>
)

export default MainLayout
