import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import request from "../util/request"
import { get } from "lodash"

export const actionRPCCURNumber = createAsyncThunk(
  "daddmktmrepeat/actionRPCCURNumber",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/daDdMktMRepeat/rpc-cur-number",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionRPCCURArea = createAsyncThunk(
  "daddmktmrepeat/actionRPCCURArea",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/daDdMktMRepeat/rpc-cur-area",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const initialState = {
  rpcCurNumber: [],
  rpcCurArea: [],
}

export const slice = createSlice({
  name: "daddmktmrepeat",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(actionRPCCURNumber.fulfilled, (state, action) => {
      state.rpcCurNumber = get(action, "payload.data")
    })
    builder.addCase(actionRPCCURNumber.rejected, (state, action) => {
      state.rpcCurNumber = []
    })
    builder.addCase(actionRPCCURArea.fulfilled, (state, action) => {
      state.rpcCurArea = get(action, "payload.data")
    })
    builder.addCase(actionRPCCURArea.rejected, (state, action) => {
      state.rpcCurArea = []
    })
  },
})

export const selectRPCCURNumber = (state) => state.daddmktmrepeat.rpcCurNumber
export const selectRPCCURArea = (state) => state.daddmktmrepeat.rpcCurArea

export default slice.reducer
