import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { persistor, store } from "./store"
import { PersistGate } from "redux-persist/integration/react"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import Routers from "./app/layouts/Routers"
import { ConfigProvider } from "antd"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <ConfigProvider
    theme={{
      token: {
        fontFamily: "pretendard-regular",
      },
      components: {
        Segmented: {
          borderRadius: 20,
          borderRadiusXS: 20,
          borderRadiusSM: 20,
        },
      },
    }}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routers />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ConfigProvider>,
)

reportWebVitals()
