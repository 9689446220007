import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { get } from "lodash"
import request from "../util/request"

export const actionGetGMV = createAsyncThunk("ddargmv/actionGetGMV", async (params, { rejectWithValue }) => {
  try {
    return await request({
      url: "/ddargmv/gmv",
      method: "GET",
      params,
    })
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const actionSaleTrends = createAsyncThunk(
  "gmv-by-business/actionSaleTrends",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/ddargmv/gmv-by-business",
        method: "GET",
        params,
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const initialState = {
  saleTrends: [],
  dataGmv: [],
  lastDate: "",
}
export const slice = createSlice({
  name: "ddargmv",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(actionSaleTrends.fulfilled, (state, action) => {
        state.saleTrends = get(action, "payload.data")
      })
      .addCase(actionSaleTrends.rejected, (state, action) => {
        state.saleTrends = []
      })
      .addCase(actionGetGMV.fulfilled, (state, action) => {
        state.dataGmv = get(action, "payload.data")[0]
        state.lastDate = get(action, "payload.data")[1]
      })
      .addCase(actionGetGMV.rejected, (state, action) => {
        state.dataGmv = []
        state.lastDate = ""
      })
  },
})

export const selectSaleTrends = (state) => state.ddargmv.saleTrends

export const selectDataGmv = (state) => state.ddargmv.dataGmv

export const selectLastDate = (state) => state.ddargmv.lastDate

export default slice.reducer
