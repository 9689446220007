const URL = {
  Login: "/login",
  Home: "/",
  CrossSell: "/cross-sell",
  Marketing: "/marketing",
  RedirectedLogin: "/login-redirected",
  CustomerBehavior: "/customer-behavior",
}

export default URL
