import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import request from "../util/request"
import { get } from "lodash"

export const actionLFTCURNumber = createAsyncThunk(
  "daddmktcouponeff/actionLFTCURNumber",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/daDdMktCouponEff/lft-cur-number",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionLFTCURArea = createAsyncThunk(
  "daddmktcouponeff/actionLFTCURArea",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/daDdMktCouponEff/lft-cur-area",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionLFTTre = createAsyncThunk("daddmktcouponeff/actionLFTTre", async (params, { rejectWithValue }) => {
  try {
    return await request({
      url: "/daDdMktCouponEff/lft-tre",
      method: "GET",
      params,
    })
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const actionLFTCpn = createAsyncThunk("daddmktcouponeff/actionLFTCpn", async (params, { rejectWithValue }) => {
  try {
    return await request({
      url: "/daDdMktCouponEff/lft-cpn",
      method: "GET",
      params,
    })
  } catch (error) {
    return rejectWithValue(error)
  }
})

const initialState = {
  lftCurNumber: [],
  lftCurArea: [],
  lftTre: [],
  lftCpn: [],
}

export const slice = createSlice({
  name: "daddmktcouponeff",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(actionLFTCURNumber.fulfilled, (state, action) => {
      state.lftCurNumber = get(action, "payload.data")
    })
    builder.addCase(actionLFTCURNumber.rejected, (state, action) => {
      state.lftCurNumber = []
    })
    builder.addCase(actionLFTCURArea.fulfilled, (state, action) => {
      state.lftCurArea = get(action, "payload.data")
    })
    builder.addCase(actionLFTCURArea.rejected, (state, action) => {
      state.lftCurArea = []
    })

    builder.addCase(actionLFTTre.fulfilled, (state, action) => {
      state.lftTre = get(action, "payload.data")
    })
    builder.addCase(actionLFTTre.rejected, (state, action) => {
      state.lftTre = []
    })

    builder.addCase(actionLFTCpn.fulfilled, (state, action) => {
      state.lftCpn = get(action, "payload.data")
    })
    builder.addCase(actionLFTCpn.rejected, (state, action) => {
      state.lftCpn = []
    })
  },
})

export const selectLFTCURNumber = (state) => state.daddmktcouponeff.lftCurNumber
export const selectLFTCURArea = (state) => state.daddmktcouponeff.lftCurArea
export const selectLFTTre = (state) => state.daddmktcouponeff.lftTre
export const selectLFTCpn = (state) => state.daddmktcouponeff.lftCpn

export default slice.reducer
