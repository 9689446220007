import { AppstoreOutlined, MailOutlined, SettingOutlined } from "@ant-design/icons"
import moment from "moment"

export const ERROR_MESSAGE = "Error"
export const CHART_ACQUISITION_SECTION = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      align: "end",
      labels: {
        usePointStyle: true,
        font: {
          size: 9,
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 9,
        },
      },
    },
    y: {
      ticks: {
        font: {
          size: 9,
        },
      },
    },
  },
}

export const CHART_ACQUISITION_DISCLOSE = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      align: "center",
      labels: {
        usePointStyle: true,
        font: {
          size: 9,
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 9,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 9,
        },
      },
    },
  },
}

export const CHART_USER_COUNT = {
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 9,
        },
      },
    },
    cumsum: {
      beginAtZero: true,
      type: "linear",
      position: "left",
      ticks: {
        font: {
          size: 9,
        },
      },
    },
    cnt: {
      beginAtZero: true,
      type: "linear",
      position: "right",
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 9,
        },
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      align: "center",
      labels: {
        usePointStyle: true,
        font: {
          size: 9,
        },
      },
      reverse: true,
    },
  },
}

export const CHART_CROSS_SELL = {
  scales: {
    x: {
      ticks: {
        font: {
          size: 12,
        },
      },
    },
    backlog: {
      beginAtZero: true,
      type: "linear",
      position: "right",
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 12,
        },
      },
    },
    realizationAndBooking: {
      beginAtZero: true,
      type: "linear",
      position: "left",
      ticks: {
        font: {
          size: 12,
        },
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      align: "center",
    },
  },
}

export const TIME_OPTION = [
  {
    label: (
      <div style={{ width: 45 }}>
        <span>일</span>
      </div>
    ),
    value: "day",
    value_short: "D",
  },
  {
    label: (
      <div style={{ width: 45 }}>
        <span>주</span>
      </div>
    ),
    value: "week",
    value_short: "W",
  },
  {
    label: (
      <div style={{ width: 45 }}>
        <span>월</span>
      </div>
    ),
    value: "month",
    value_short: "M",
  },
]

export const TIME_OPTION_INFLOW = [
  {
    label: (
      <div style={{ width: 45 }}>
        <span>일</span>
      </div>
    ),
    value: "D",
  },
  {
    label: (
      <div style={{ width: 45 }}>
        <span>주</span>
      </div>
    ),
    value: "W",
  },
  {
    label: (
      <div style={{ width: 45 }}>
        <span>월</span>
      </div>
    ),
    value: "M",
  },
]

export const TIME_OPTION_GMV = [
  {
    label: (
      <div style={{ width: 45 }}>
        <span>일</span>
      </div>
    ),
    value: "day",
  },
  {
    label: (
      <div style={{ width: 45 }}>
        <span>월</span>
      </div>
    ),
    value: "month",
  },
  {
    label: (
      <div style={{ width: 45 }}>
        <span>년</span>
      </div>
    ),
    value: "annual",
  },
]

export const TIME_CROSS_SELL_OPTION = [
  {
    label: (
      <div style={{ width: 45 }}>
        <span>3일</span>
      </div>
    ),
    value: "3d",
  },
  {
    label: (
      <div style={{ width: 45 }}>
        <span>7일</span>
      </div>
    ),
    value: "7d",
  },
  {
    label: (
      <div style={{ width: 45 }}>
        <span>14일</span>
      </div>
    ),
    value: "14d",
  },
  {
    label: (
      <div style={{ width: 45 }}>
        <span>365일</span>
      </div>
    ),
    value: "365d",
  },
]

export const TIME_CROSS_SELL_OPTION_CROSS_PAGE = [
  {
    label: (
      <div style={{ width: 65, textAlign: "right" }}>
        <span>3일 내</span>
      </div>
    ),
    value: "3d",
  },
  {
    label: (
      <div style={{ width: 65, textAlign: "right" }}>
        <span>7일 내</span>
      </div>
    ),
    value: "7d",
  },
  {
    label: (
      <div style={{ width: 65, textAlign: "right" }}>
        <span>14일 내</span>
      </div>
    ),
    value: "14d",
  },
  {
    label: (
      <div style={{ width: 65, textAlign: "right" }}>
        <span>1년 내</span>
      </div>
    ),
    value: "365d",
  },
]

export const crossTypes = [
  {
    value: "항공,호텔",
    label: "항공 > 호텔",
  },
  {
    value: "항공,투티",
    label: "항공 > 투티",
  },
  {
    value: "호텔,항공",
    label: "호텔 > 항공",
  },
  {
    value: "호텔,투티",
    label: "호텔 > 투티",
  },
  {
    value: "투티,항공",
    label: "투티 > 항공",
  },
  {
    value: "투티,호텔",
    label: "투티 > 호텔",
  },
]

export const countType = [
  {
    value: "users",
    label: "사용자",
  },
  {
    value: "days",
    label: "일수",
  },
]

export const WEB_SERVICE_NAME = {
  TIDESQUARE: "tidesquare",
  TOURVIS: "tourvis",
  PRIVIA: "privia",
}

export const MAJOR_INDICATOR_TYPE = {
  newSale: "신규단독판매",
  crossSelling: "교차판매",
  crossSellingRight: "잔여단독판매",
  crossSellingRatio: "교차판매율",
}

export const MAJOR_INDICATOR_LABEL_LEGEND = {
  users_booking: "신규단독판매",
  users_realization: "교차판매",
  users_rob: "교차판매율",
  days_booking: "신규단독판매",
  days_realization: "교차판매",
  days_rob: "교차판매율",
}

export const FUNNEL_FILTER = [
  {
    label: "전체",
    value: "E",
  },
  {
    label: "항공",
    value: "A",
  },
  {
    label: "호텔",
    value: "H",
  },
  {
    label: "투어&티켓",
    value: "T",
  },
]

export const ITEMSMENUKPIS = [
  {
    key: 1,
    label: (
      <span style={{fontSize: 14}}>
        전체
      </span>
    ),
    value: "E",
  },
  {
    key: 2,
    label: (
      <span style={{fontSize: 14}}>
        항공
      </span>
    ),
    value: "A",
  },
  {
    key: 3,
    label: (
      <span style={{fontSize: 14}}>
        호텔
      </span>
    ),
    value: "H",
  },
  {
    key: 4,
    label: (
      <span style={{fontSize: 14}}>
        투어&티켓
      </span>
    ),
    value: "T",
  },
]
export const Convert_ITEMSMENUKPIS = (text) => {
  if (text === "E") return "ALL"
  if (text === "A") return "AIR"
  if (text === "H") return "HTL"
  if (text === "T") return "TNT"
  return text
}
export const ITEMSMENUKPI = [
  {
    label: "판매트렌드",
    key: "mail",
    icon: <MailOutlined />,
  },
  {
    label: "판매트",
    key: "app",
    icon: <AppstoreOutlined />,
  },
  {
    label: "판매",
    key: "SubMenu",
    icon: <SettingOutlined />,
  },
  {
    label: "판매트렌드",
    key: "alipay",
  },
]
export const ITEMSMENUKPIVSTARGET = [
  {
    label: <span style={{ padding: "0 10px" }}>전체</span>,
    key: "mail",
  },
  {
    label: <span style={{ padding: "0 10px" }}>항공</span>,
    key: "app",
  },
  {
    label: <span style={{ padding: "0 10px" }}>호텔</span>,
    key: "SubMenu",
  },
  {
    label: <span style={{ padding: "0 10px" }}>투어&티켓</span>,
    key: "alipay",
  },
]

export const AGE_FILTER = [
  {
    value: "10대",
    label: <span style={{ marginRight: 6 }}>10대</span>,
  },
  {
    value: "20대",
    label: <span style={{ marginRight: 6 }}>20대</span>,
  },
  {
    value: "30대",
    label: <span style={{ marginRight: 6 }}>30대</span>,
  },
  {
    value: "40대",
    label: <span style={{ marginRight: 6 }}>40대</span>,
  },
  {
    value: "50대",
    label: <span style={{ marginRight: 6 }}>50대</span>,
  },
  {
    value: "60대이상",
    label: <span style={{ marginRight: 6 }}>60대 이상</span>,
  },
  {
    value: "알수없음",
    label: <span style={{ marginRight: 6 }}>알 수 없음</span>,
  },
  {
    value: "",
    label: <span style={{ marginRight: 6, color: "gray" }}>초기화</span>,
  },
]

export const GENDER_FILTER = [
  {
    value: "여성",
    label: <span style={{ marginRight: 6 }}>여성</span>,
  },
  {
    value: "남성",
    label: <span style={{ marginRight: 6 }}>남성</span>,
  },
  {
    value: "알수없음",
    label: <span style={{ marginRight: 6 }}>알 수 없음</span>,
  },
  {
    value: "",
    label: <span style={{ marginRight: 6, color: "gray" }}>초기화</span>,
  },
]
export const formatWebservice = (webService) => {
  let newS = ""
  if (webService === "tidesquare" || webService === "tourvis") {
    newS = "T"
    return newS
  } else newS = "P"
  return newS
}

export const formatWebserviceOriginal = (webService) => {
  return webService.toUpperCase()
}

export const formatWebserviceKorea = (webService) => {
  let newS = ""
  if (webService === "tidesquare" || webService === "tourvis") {
    newS = "투어비스"
    return newS
  } else newS = "프리비아"
  return newS
}

export const PUSH_FILTER = [
  {
    label: "전체",
    value: "Total",
  },
  {
    label: "앱푸시(AP)",
    value: "AppPush",
  },
  {
    label: "채널메세지(CH)",
    value: "ChannelMsg",
  },
  {
    label: "이메일(EM)",
    value: "Email",
  },
  {
    label: "메시지(MS)",
    value: "LMS",
  },
]
export const SEARCH_FILTER = [
  {
    label: "전체",
    value: "Total",
  },
  {
    label: "검색(SA)",
    value: "SearchAD",
  },
  {
    label: "쇼핑검색(SS)",
    value: "ShoppingSearchAD",
  },
]

export const DISPLAY_FILTER = [
  {
    label: "전체",
    value: "Total",
  },
  {
    label: "브랜드검색(BS)",
    value: "BrandSearch",
  },
  {
    label: "배너(DA)",
    value: "DisplayAD",
  },
  {
    label: "네이티브(NA)",
    value: "NativeAD",
  },
  {
    label: "비디오(VD)",
    value: "Video",
  },
]
export const NI_FILTER = [
  {
    label: "전체",
    value: "Total",
  },
  {
    label: "Direct",
    value: "Direct",
  },
  {
    label: "OrganicSearch",
    value: "OrganicSearch",
  },
  {
    label: "Referral",
    value: "Referral",
  },
  {
    label: "Affiliate",
    value: "Affiliate",
  },
  {
    label: "Help",
    value: "Help",
  },
  {
    label: "Other",
    value: "Other",
  },
]
export const FILTER_CVR = [
  {
    label: "항공",
    value: "A",
  },
  {
    label: "호텔",
    value: "H",
  },
  {
    label: "투어&티켓",
    value: "T",
  },
]
export const FILTER_BCR = [
  {
    label: "항공",
    value: "AIR",
  },
  {
    label: "호텔",
    value: "HTL",
  },
  {
    label: "투어&티켓",
    value: "TNT",
  },
  {
    label: "기타",
    value: "ETC",
  },
]
export const FILTER_BCT = [
  {
    label: "전체",
    value: "ALL",
  },
  {
    label: "항공",
    value: "AIR",
  },
  {
    label: "호텔",
    value: "HTL",
  },
  {
    label: "투어&티켓",
    value: "TNT",
  },
  {
    label: "기타",
    value: "ETC",
  },
]
export const PUSH_FILTER_BCR = [
  {
    label: "전체",
    value: "Total",
  },
  {
    label: "앱푸시(AP)",
    value: "AppPush",
  },
  {
    label: "채널메세지(CH)",
    value: "ChannelMsg",
  },
  {
    label: "이메일(EM)",
    value: "Email",
  },
  {
    label: "메시지(MS)",
    value: "LMS",
  },
]
export const SEARCH_FILTER_BCR = [
  {
    label: "전체",
    value: "Total",
  },
  {
    label: "검색(SA)",
    value: "SearchAD",
  },
  {
    label: "쇼핑검색(SS)",
    value: "ShoppingSearchAD",
  },
]

export const DISPLAY_FILTER_BCR = [
  {
    label: "전체",
    value: "Total",
  },
  {
    label: "브랜드검색(BS)",
    value: "BrandSearch",
  },
  {
    label: "배너(DA)",
    value: "DisplayAD",
  },
  {
    label: "네이티브(NA)",
    value: "NativeAD",
  },
  {
    label: "비디오(VD)",
    value: "Video",
  },
]
export const NI_FILTER_BCR = [
  {
    label: "전체",
    value: "Total",
  },
  {
    label: "Direct",
    value: "Direct",
  },
  {
    label: "OrganicSearch",
    value: "OrganicSearch",
  },
  {
    label: "Referral",
    value: "Referral",
  },
  {
    label: "Affiliate",
    value: "Affiliate",
  },
  {
    label: "Help",
    value: "Help",
  },
  {
    label: "Other",
    value: "Other",
  },
]
export const ONE_PAGE_BCR_AIR = [
  {
    label: "전체",
    value: "Total",
  },
  {
    label: "Category Page",
    value: "category",
  },
  {
    label: "Search Page",
    value: "search",
  },
]
export const ONE_PAGE_BCR_HTL = [
  {
    label: "전체",
    value: "Total",
  },
  {
    label: "Category Page",
    value: "category",
  },
  {
    label: "Search Page",
    value: "search",
  },
  {
    label: "Product Page",
    value: "product",
  },
]
export const ONE_PAGE_BCR_TNT = [
  {
    label: "전체",
    value: "Total",
  },
  {
    label: "Category Page",
    value: "category",
  },
  {
    label: "Search Page",
    value: "search",
  },
  {
    label: "Product Page",
    value: "product",
  },
]
export const ONE_PAGE_BCR_ETC = [
  {
    label: "전체",
    value: "Total",
  },
  {
    label: "Main Page",
    value: "main",
  },
]
export const dateFormat = "YYYY-MM-DD"
export const monthFormat = "YYYY-MM"

export const DEFAULT_DATE_RANGE_FROM_YESTERDAY = [
  moment().subtract(1, 'day').subtract(1, 'month').format(dateFormat), // 1 month before yesterday
  moment().subtract(1, 'day').format(dateFormat), // yesterday
]

export const COMPONENT_TOOLTIP_CONTENT = {
  newSale: "신규 판매 중에서 교차판매 후행사업이 아닌 판매수",
  crossSell: "교차판매 후행사업의 판매수",
  crossSellRatio: "교차판매 / 신규단독판매",
  remainingSingleSale: "주어진 기간 내의 판매 중에서 교차판매가 되지 않은 판매수 (교차판매 타겟이 되는 판매수)",
  keyMetricsCrossSell: "신규단독판매, 교차판매, 교차판매율, 잔여단독판매",
  histogramCrossSell:
    "교차판매 리드타임(교차판매 후행사업과 선행사업의 판매일 차이)에 대한 누적 밀도 히스토그램 (최대 14일)",
  kpiCustomerBCR: "유입된 사용자(bid) 중에서 (24시간 이내) 다른 페이지에 진입하지 않고 이탈한 사용자 비중(%)",
  kpiCustomerCVR: "유입된 사용자(bid) 중에서 상품을 결제한 사용자 비중(%)",
  kpiVsTarget: "당해연도 목표치와 KPI 현황",
  genderStatus: ["남 / 여 / 알 수 없음", "수집되지 않은 성별 데이터는 ‘알 수 없음’으로 처리함"],
  ageStatus: [
    "10대 / 20대 / 30대 / 40대 / 50대 / 60대 이상 / 알 수 없음",
    "매년 1월 1일을 기점으로 연령대를 갱신함",
    "수집되지 않은 연령대 데이터는 ‘알 수 없음’으로 처리함",
  ],
  newInflow: ["신규: 신규 유입한 사용자(bid)", "기존: 기존 유입한 사용자(bid)"],
  conversionRateTrends: ["유입: 공통 및 사업별 메인 페이지", "조회: 상품 상세 등 조회 페이지", "결제: 결제 페이지"],
  bounceRateTrend: "유입된 사용자(bid) 중에서 (24시간 이내) 다른 페이지에 진입하지 않고 이탈한 사용자 비중(%)",
  CVRCHN_BCRCHN: [
    ["Push", "AP: AppPush", "CH: ChannelMsg", "EM: E-mail", "MS: LMS"],
    ["Search", "SA: SearchAD", "SS: ShoppingSearchAD"],
    ["Display", "BS: BrandSearch", "DA: DisplayAD", "NA: NativeAD", "VD: Video"],
    ["NI", "Direct", "OrganicSearch", "Referral", "Affiliate", "Help", "Other", "Unknown"],
  ],
  BCRPAG: ["Main page", "Category page", "Search 결과 페이지", "Product page"],
  marketingKPIAET: "당월 집행 누적 광고비(매체비)",
  marketingKPIMAU: "최근 1년 내 APP으로 로그인 한 회원",
  marketingKPINC: "당월 첫 구매 고객",
  marketingKPIRC: "일정 기간 내 상품 구매 건수가 2건 이상인 고객",
  marketingKPILETOrLift: "판매촉진비 1원 지출 당 발생한 GMV",
  MAUAndUVTrends: ["MAU: 최근 1년 내 APP으로 로그인한 회원", "UV: 순 방문자 수(bid)"],
  UVTrendsByPlatform: ["PC: PC 디바이스 WEB 접속", "M_WEB: 모바일 WEB 접속", "APP: APP 접속"],
  landingUVTrendByBusiness: "고객이 유입한 서비스 페이지에 따라 메인 / 항공 / 호텔 / 투어&티켓 / 기타로 구분함",
  analyzingRepeatCustomers: "조회일 기준 어제부터 6개월 내 구매 건수가 2건 이상인 고객",
  statusRepurchasingCustomers: "조회일 기준 어제부터 6개월 내 구매 건수가 2건 이상인 고객 비중(%)",
  measuringMarketingEfficiency: [
    "광고비: 매체비",
    "발송량: utm별 광고 발송량",
    "클릭: 클릭한 고객 수(bid)",
    "유입: 유입된 고객 수(bid)",
    "구매: 구매한 고객 수(bid)",
    "신규 가입: 신규 가입한 회원 수",
  ],
  measuringCouponEfficiency: [
    "시작일/종료일: 쿠폰 프로모션 시작 및 종료 일자",
    "할인: 정률(%)/정액(원)",
    "사용 고객: 쿠폰을 사용한 고객",
    "신규 고객: 쿠폰을 첫 사용한 고객",
    "GMV: 발생 GMV",
    "할인금액: 사용 판촉비",
    "Lift 효율: 판촉비 1원당 발생한 GMV",
  ],
}

export const MEMBER_FILTER = {
  ageGroup: {
    label: "연령대 현황",
    value: "age_group",
    placeholder: "성별",
  },
  gender: {
    label: "성별 현황",
    value: "gender",
    placeholder: "연령대",
  },
}

export const KPI_CUSTOMER_PROPS = {
  cvr: {
    label: "결제 전환율",
    rateType: "conversion_rate",
  },
  bcr: {
    label: "Bounce Rate",
    rateType: "bounce_rate",
  },
}

export const CATE_COLUMN_PROPS = {
  acquisitionDisclose: {
    label: "유입 경로",
    xField: "time_value",
    yField: "total",
  },
  subscriberRouter: {
    label: "가입경로",
    xField: "display_dt",
    yField: "cnt",
  },
}

export const NEW_INFLOW_PROPS = { label: "신규 유입" }
export const CR_TRENDS_PROPS = { label: "전환율 추이" }
export const BR_TRENDS_PROPS = { label: "Bounce Rate 추이" }
export const CR_TRENDS_BYINFLOW_PROPS = { label: "유입채널별 전환율 추이" }
export const BR_TRENDS_BYINFLOW_PROPS = { label: "유입채널별 Bounce rate 추이" }
export const BR_TRENDS_BYPAGE_PROPS = { label: "항공 bounce rate 추이" }
export const LIFT_EFFICIENCY_TREND_PROPS = { label: "Lift 효율 추이"}
export const MEASURING_COUPON_EFFICIENCY_PROPS = { label: "쿠폰 효율 측정" }
export const STATUS_REPURCHASING_CUSTOMERS_PROPS = { label: "재구매 고객 현황" }
export const ANALYZING_REPEAT_CUSTOMERS_PROPS = { label: "재구매 고객 분석" }
export const MAU_UV_TRENDS_PROPS = { label: "MAU 및 UV 추이" }
export const UV_TRENDS_BYPLATFORM_PROPS = { label: "플랫폼별 UV 추이" }
export const LANDING_UV_TREND_BYBUSINESS_PROPS = { label: "사업별 랜딩 UV 추이" }

export const TITLE_HEADER = "Welcome Back"
export const SUB_TITLE_HEADER = "Here’s what’s happening with your statistic today."

export const GEOMATRIES_TYPE = {
  line: "line",
  shape: "shape",
}

export const TIMES_VALUE = {
  month: "month",
  week: "week",
  day: "day",
  M: "M",
  W: "W",
  D: "D",
}

export const WEB_SERVICE_NAME_KR = {
  tidesquare: "투어비스",
  tourvis: "투어비스",
  privia: "프리비아",
}

const yesterdayDate = moment().subtract(1, "day").format("YYYY-MM-DD")
export const FOOTER_TOOLTIP_CONTENT_GENDER_AGE_STATUS = `※ 데이터 기준일: ${yesterdayDate}`
export const FOOTER_TOOLTIP_CONTENT_CRT = "※ 항공 데이터의 경우 추후 보완 예정임"
export const FOOTER_TOOLTIP_CONTENT_BCRPAG = "※ 각 사업별로 bounce rate을 측정하는 페이지는 다를 수 있음"
export const FOOTER_TOOTIP_MARKETING_KPIMAU = "휴면/탈퇴/계정 정지 회원 미포함"
export const FOOTER_TOOLTIP_ANALYZING_REPEAT = "※ 국내와 해외 상품을 교차 구매했을 경우 구매 건수가 중복 계산되어 소계와 합계의 차이가 발생할 수 있음"

export const SUB_TOTAL = "소계"
export const DATE_HEADER_EXCEL = moment().format("YY-MM-DD")

export const EMPTY_CELL_MARKETING_TABLE = "-"
export const TABLE_CELL_TEXT_TOTAL = {
  total: "Total",
  subTotal: "Sub Total"
}

export const MARKETING_KPI_PROPS = {
  adExpenses: {
    title: "광고비",
    unit: "원",
    type: "adExpenses"
  },
  mau: {
    title: "MAU",
    unit: "명",
    type: "MAU"
  },
  newCustomer: {
    title: "신규 구매 고객",
    unit: "명",
    type: "newCustomer"
  },
  repeatCustomer: {
    title: "재구매 고객",
    unit: "명",
    type: "repeatCustomer"
  },
  liftEfficiency: {
    title: "Lift 효율",
    unit: "원",
    type: "liftEfficiency"
  }
}

export const CRTREND_OBJECT_RESPONSE_KEYS = ["conversion_rate", "inflow", "search", "conversion"]
export const BRTREND_OBJECT_RESPONSE_KEYS = ["bounce_rate", "inflow", "bounce"]

export const DATE_PICKER_DEFAULT_MONTH_OPTIONS = {
  value: "초기화",
  label: "초기화"
}