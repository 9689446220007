import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import request from "../util/request"
import { get } from "lodash"

export const actionCUSCURNumber = createAsyncThunk(
  "daddmktfirstcnt/actionCUSCURNumber",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/daDdMktFirstCnt/cus-cur-number",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const actionCUSCURArea = createAsyncThunk(
  "daddmktfirstcnt/actionCUSCURArea",
  async (params, { rejectWithValue }) => {
    try {
      return await request({
        url: "/daDdMktFirstCnt/cus-cur-area",
        method: "GET",
        params,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const initialState = {
  cusCurNumber: [],
  cusCurArea: [],
}

export const slice = createSlice({
  name: "daddmktfirstcnt",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(actionCUSCURNumber.fulfilled, (state, action) => {
      state.cusCurNumber = get(action, "payload.data")
    })
    builder.addCase(actionCUSCURNumber.rejected, (state, action) => {
      state.cusCurNumber = []
    })
    builder.addCase(actionCUSCURArea.fulfilled, (state, action) => {
      state.cusCurArea = get(action, "payload.data")
    })
    builder.addCase(actionCUSCURArea.rejected, (state, action) => {
      state.cusCurArea = []
    })
  },
})

export const selectCUSCURNumber = (state) => state.daddmktfirstcnt.cusCurNumber
export const selectCUSCURArea = (state) => state.daddmktfirstcnt.cusCurArea

export default slice.reducer
